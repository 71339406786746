.carousel {
	margin-bottom: 10px;
}

.carousel-inner > .item{
	height: 300px;
}


.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
	width: 90%;
	margin: auto;
	position: absolute;
	top:0;
	bottom:0;
	left: 0;
	right: 0;
	min-height: 100%;
}